import React from 'react';

import { DynamicContentComponent } from '@lerna-monorepo/common/components/dynamicContentComponent/dynamicContentComponent.component';
import { BigTextHeader } from '@lerna-monorepo/common/components/bigTextHeader/bigTextHeader.component';
import { DynamicContent } from '@lerna-monorepo/common/interfaces/dynamicContent.types';

import { TextPageData } from './textPage.types';
import { DynamicContentComponentContainer } from './textPage.styled';

const TextPage: React.FC<TextPageData> = (pageData: TextPageData) => {
  const data = pageData.pageContext.data;

  const renderContent = () =>
    data.acf.textPageData.map((textPageDataItem: DynamicContent) => (
      <DynamicContentComponentContainer
        key={`${textPageDataItem.header.bigHeader.mainContent}${textPageDataItem.header.bigHeader.descriptionContent}`}
      >
        <DynamicContentComponent
          header={textPageDataItem.header}
          dynamicContent={textPageDataItem.dynamicContent}
        />
      </DynamicContentComponentContainer>
    ));

  return (
    <>
      <BigTextHeader content={data.title} />
      {renderContent()}
    </>
  );
};

export default TextPage;
